<template>
  <div class="Filtersearch">
    <div class="Filtersearchinput">
      <div
        class="Filtersearchinput-item"
        v-for="(item, index) in itemindex"
        :key="index"
      >
        <div class="wen-item">
          <div class="wen-item-tips">
            <el-tooltip
              v-if="Filtersearchlist[index].msg"
              class="item"
              effect="dark"
              :content="Filtersearchlist[index].msg"
              placement="top-start"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
          {{ Filtersearchlist[index].name }}
        </div>
        <div class="wen-input">
          <el-input
            v-if="Filtersearchlist[index].type == 'input'"
            v-model="from[Filtersearchlist[index].model]"
            style="width: 100%"
            :placeholder="Filtersearchlist[index].placeholder"
            :clearable="Filtersearchlist[index].clearable"
          ></el-input>
          <el-select
            v-else-if="Filtersearchlist[index].type == 'select'"
            v-model="from[Filtersearchlist[index].model]"
            style="width: 100%"
            :clearable="Filtersearchlist[index].clearable"
            :filterable="Filtersearchlist[index].filterable"
            :multiple="Filtersearchlist[index].multiple"
            :placeholder="Filtersearchlist[index].placeholder"
          >
            <el-option
              :label="itenchild[Filtersearchlist[index].label]"
              v-for="(itenchild, i) in Filtersearchlist[index].selectoption"
              :value="itenchild[Filtersearchlist[index].value]"
              :key="i"
            ></el-option>
          </el-select>
          <el-select
            v-else-if="Filtersearchlist[index].type == 'selectChange'"
            v-model="from[Filtersearchlist[index].model]"
            style="width: 100%"
            :clearable="Filtersearchlist[index].clearable"
            :filterable="Filtersearchlist[index].filterable"
            :placeholder="Filtersearchlist[index].placeholder"
            @change="selectChange"
          >
            <el-option
              :label="itenchild[Filtersearchlist[index].label]"
              v-for="(itenchild, i) in Filtersearchlist[index].selectoption"
              :value="itenchild[Filtersearchlist[index].value]"
              :key="i"
            ></el-option>
          </el-select>
          <el-date-picker
            v-else-if="Filtersearchlist[index].type == 'daterange'"
            type="daterange"
            :clearable="Filtersearchlist[index].clearable"
            style="width: 100%"
            v-model="from[Filtersearchlist[index].model]"
            :start-placeholder="Filtersearchlist[index].startplaceholder"
            :end-placeholder="Filtersearchlist[index].endplaceholder"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="(val) => addTimeChange(val, Filtersearchlist[index])"
          >
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="btn-expand" v-if="Filtersearchlist.length > 5">
      <el-button type="text" v-if="downshow" @click="downshowchange"
        ><i class="el-icon-arrow-down"></i> 展开</el-button
      >
      <el-button type="text" v-else @click="downshowchange"
        ><i class="el-icon-arrow-up"></i> 收起</el-button
      >
    </div>
    <div class="Filtersearchserch">
      <div class="Filtersearchserch-left">
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="Refresh"
        ></el-button>

        <slot name="left"></slot>
      </div>
      <div class="Filtersearchserch-right">
        <el-button type="primary" icon="el-icon-search" @click="search"
          >查询</el-button
        >
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /** 文件列 */
    list: {
      type: Array,
      default: () => [],
    },
    /** 文件列 */
    fromdata: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      downshow: true,
      Filtersearchlist: [],
      from: {},
      itemindex: 5,
    };
  },
  watch: {
    list: {
      handler(newVal, oldVal) {
        this.Filtersearchlist = JSON.parse(JSON.stringify(newVal));
        this.itemindex =
          this.Filtersearchlist.length > 5 ? 5 : this.Filtersearchlist.length;
        this.downshow = true;
      },
      deep: true,
      immediate: true,
    },
    fromdata: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal, "222");
        this.getfrom();
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.Filtersearchlist = JSON.parse(JSON.stringify(this.list));
    this.itemindex =
      this.Filtersearchlist.length > 5 ? 5 : this.Filtersearchlist.length;
  },
  mounted() {
    this.getfrom();
  },
  methods: {
    downshowchange() {
      this.downshow = !this.downshow;
      if (this.downshow) {
        this.itemindex =
          this.Filtersearchlist.length > 5 ? 5 : this.Filtersearchlist.length;
      } else {
        this.itemindex = this.Filtersearchlist.length;
      }
    },
    gettime(e) {
      this.Filtersearchlist.forEach((el) => {
        if (el.type == "daterange") {
          if (this.from[el.startmodel]) {
            this.from[el.model] = [
              this.from[el.startmodel],
              this.from[el.endmodel],
            ];
          }
        }
      });
    },
    addTimeChange(val, e) {
      if (val) {
        this.from[e.startmodel] = val[0];
        this.from[e.endmodel] = val[1];
      } else {
        this.from[e.startmodel] = "";
        this.from[e.endmodel] = "";
      }
    },
    getfrom() {
      this.from = JSON.parse(JSON.stringify(this.fromdata));
      this.gettime();
      console.log("111,ce1", this.from);
    },
    Refresh() {
      this.$emit("Refresh");
    },
    search() {
      this.$emit("search", this.from);
    },
    // 选择下拉  比如选择仓 获取仓id 请求自提点列表
    selectChange(val) {
      console.log(this.from, "eee");
      this.$emit("selectChange", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.Filtersearch {
  .bjsearch {
    background: #333333;
    border-color: #333333;
  }
  margin-bottom: 10px;

  .Filtersearchinput {
    background: #ffffff;

    padding: 12px 0px;
    padding-bottom: 0;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    .Filtersearchinput-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .wen-item {
        width: 95px;

        margin-right: 8px;
        font-weight: 400;
        font-size: 14px;
        color: #535252;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .wen-item-tips {
          margin-right: 5px;
          padding-top: 5px;
          box-sizing: border-box;
        }
      }
      .wen-input {
        width: 220px;
        /deep/ .el-date-editor--daterange {
          .el-range__icon {
            display: none !important;
          }
        }
      }
    }
  }
  .Filtersearchserch {
    margin-top: 10px;
    background: #ffffff;
    padding: 12px 15px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .Filtersearchserch-left {
      flex: 1;
    }
    .Filtersearchserch-right {
    }
  }
  .btn-expand {
    padding: 0px 15px;
    // padding-top: 0;
    background: #ffffff;
    box-sizing: border-box;
    text-align: right;
  }
}
</style>
